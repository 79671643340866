@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('ttf');
  font-weight: 700;
}

.header-linear-gradient {
  @apply bg-gradient-to-r from-plum to-peach;
}

.bg-linear-gradient {
  @apply bg-gradient-to-br from-skyOpacity via-peacOpacity to-lemonOpacity;
}

.btn-dropdown {
  @apply px-4 py-2 text-base font-light tracking-wider text-gray-800 bg-white border border-gray-400 rounded shadow hover:bg-gray-200;
}

@layer utilities {
    .bg-diagonal-plum-stripes {
        background: repeating-linear-gradient(
            135deg,                             /* Diagonal stripes */
            #ffffff,                          /* Starting color */
            #ffffff 15px,                     /* Width of the white stripe */
            rgba(146, 4, 74, 0.7) 5px,        /* Starting color of the plum stripe */
            rgba(146, 4, 74, 0.7) 20px        /* Width of the plum stripe */
        );
    }
    .bg-diagonal-lemon-stripes {
        background: repeating-linear-gradient(
            135deg,                              /* Diagonal stripes */
            rgba(243, 210, 120, 0.7),          /* Starting color */
            rgba(243, 210, 120, 0.7) 15px,     /* Width of the white stripe */
            rgba(146, 4, 74, 0.7) 5px,         /* Starting color of the lemon stripe */
            rgba(146, 4, 74, 0.7) 20px         /* Width of the plum stripe */
        );
    }
}

.up {
  animation: upanimation 200ms ease-in-out forwards;
}
.down {
  animation: downanimation 200ms ease-in-out forwards;
}

.main-input {
  width: 40rem;
}

.option-box {
  transform: translateX(-200px);
  transition: 200ms ease-in-out;
}

.option-box:hover {
  transform: translateX(0);
}

.option {
  transition: all 200ms ease-in-out;
}
.option-box:hover > .option {
  transform: translateX(0);
  transform: translateY(0);
  transform: rotate(0);
}

.action-container {
  min-width: 40rem;
  max-width: 40rem;
}
.dropdown_animation {
  animation: dropdown ease-in-out;
  animation-duration: 700ms;
}
.error-text {
  font-size: 130px;
}

.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

.description__multicol {
  column-count: 2;
  column-gap: 20px;
  column-rule-style: solid;
  column-rule-color: rgb(75 85 99);
}
@media (min-width: 768px) {
  .error-text {
    font-size: 220px;
  }
}
@keyframes upanimation {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes downanimation {
  0% {
    transform: translateY(+40px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popin {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes dropdown {
  0% {
    transform: translateY(100%);
  }
  40% {
    transform: translateY(-10%);
  }
  70% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media print {
  .not-in-pdf {
    display: none;
  }
  .pdf-content {
    width: 1000px;
    margin-left: 0;
    padding: 0 5rem;
  }
}

@media screen and (max-height: 300px) {
  .shareview_responsive {
    display: none;
  }
}
